import React from 'react'

function MobileFooter() {
  return (
    <>
    <div className='footer-mobile'>
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <div className='d-flex align-items-center justify-content-between terms-row'>
                        <a href='/' className="">Terms and Conditions</a>
                        <a href='/' className="">Privacy Policy</a>
                        <a href='/' className="">Refund Policy</a>
                    </div>

                    <div className='d-flex align-items-center justify-content-center copy-right'>
                        Copyright © 2024 <a href='#' className=''>Demon Official Store</a>. All Rights Reserved.
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default MobileFooter