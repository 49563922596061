import { useEffect, useState } from "react";
import Header from '../components/Header'
import Footer from '../components/Footer'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom';
import MobileHeader from "../components/MobileHeader";
import MobileFooter from "../components/MobileFooter";


function Login() {
  const navigate = useNavigate()

  const [value, setValue] = useState()
  return (
    <>
    <div className="mainheaderFooter">
      <Header/>
    </div>
    <MobileHeader/>
     <section className="wrapper">
        <div className='container pb-5'>
          <div className='row d-flex justify-content-center'>
            <div className='col-12 col-md-6 my-md-5 mb-4'>
              <form className='login-form'>
                <h2 className='text-center'>Welcome!</h2>
                <h6 className='text-center'>Enter your phone number</h6>

                <div className=''>
                  <PhoneInput
                    class="form-control form-control-lg input-box"
                    placeholder="Phone number"
                    international
                    defaultCountry="IN"
                    value={value}
                    onChange={setValue}/>
                </div>

                <button type='button' className='btn btn-pay w-100' onClick={() => navigate("/otp")}>Send OTP</button>

                <h5 class="right-line left-line center-text">Or continue with </h5>
                <div className='login-social'>
                  <a href="#" class="btn btn-social">
                    <img src="images/google.svg" alt="google" />
                  </a>
                  <a href="#" class="btn btn-social fb-btn">
                    <img src="images/fb.svg" alt="facebook" />
                  </a>
                  <a href="#" class="btn btn-social">
                    <img src="images/apple.svg" alt="apple" />
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
     </section>
     <div className="mainheaderFooter">
      <Footer/>
     </div>
    <MobileFooter/>
    </>
  )
}

export default Login