import React from 'react'

function Footer() {
  return (
    <>

    <footer className="pt-5 pb-3">
        <div className="container">
            <div className="row g-4 pb-4">
                <div className="col-lg-3 col-sm-6">
                    <h6 className="">Our Company</h6>
                    <ul>
                        <li>
                            <a href="#" className="">Terms and Conditions</a>
                        </li>
                        <li>
                            <a href="#" className="">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" className="">Refund Policy</a>
                        </li>
                        <li>
                            <a className="">About Us</a>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <h6 className="">Catagory</h6>
                    <ul>
                        <li>
                            <a href="#" className="">Popular Games</a>
                        </li>
                        <li>
                            <a href="#" className="">Rewards Center</a>
                        </li>
                        <li>
                            <a href="#" className="">My Account</a>
                        </li>
                        <li>
                            <a className="">Reseller</a>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <h6 className="">Support</h6>
                    <ul>
                        <li>
                            <a href="#" className="">admin@demonofficialstore.com</a>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <h6 className="">Download the App</h6>

                    <div className='d-none d-lg-block'>
                        <div className='download-app d-flex align-items-center justify-content-around '>
                            <a href='#' target="_blank" className='white-box'></a>
                            <a href='#' target="_blank" className='game-box'>
                                <img src='images/game.png' alt='game'/>
                            </a>
                        </div>
                    </div>

                    <div className='d-flex gap-4 d-block d-lg-none pb-2'>
                        <button type='button' className='btn border-0 p-0 m-0'>
                            <img className='w-100' src='images/playstore.svg' alt='playstore'/>
                        </button>

                        <button type='button' className='btn border-0 p-0 m-0'>
                            <img className='w-100 h-100' src='images/app-store.svg' alt='app store'/>
                        </button>
                    </div>
                </div>
            </div>

            <div className='row row-cols-1 row-cols-md-2 g-4 pb-4'>
                <div className='col'>
                    <div className='navbar-brand'>
                        <a href='/'>
                            <img src='images/logo.png'/>
                        </a>
                    </div>
                </div>
                <div className='col d-flex footer-socialicon'>
                    <div className='ms-md-auto'>
                        <h5 className='text-md-center pb-2'>Follow us on :</h5>
                        <div className='d-flex align-items-center justify-content-between'>
                            <a href='#' className='facebook'>
                                <img src='images/facebook.svg' alt='facebook'/>
                            </a>
                            <a href='#' className='instagram'>
                                <img src='images/instagram.svg' alt='instagram'/>
                            </a>
                            <a href='#' className='youtube'>
                                <img src='images/youtube.png' alt='youtube'/>
                            </a>
                            <a href='#' className='telegram'>
                                <img src='images/telegram.svg' alt='telegram'/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center align-items-center copyright pt-2'>
                Copyright © 2024 Demon Official Store. All Rights Reserved.
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer