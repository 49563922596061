import {React,useState} from 'react'
import {BrowserRouter as Router,Switch,Route,Link,NavLink} from "react-router-dom"
import {Button,Modal} from 'react-bootstrap';

function MobileHeader() {
  return (
    <>
    <div className='nav-mobile'>
        <div className='container py-4'>
            <div className='row'>
                <div className='col-12 d-flex align-items-center'>
                     <button className="login-back" type="button" aria-label="back navigation">
                        <svg className="icon">
                            <use href="#icon_backarrow"></use>
                        </svg>
                    </button>

                    <div className='mx-auto'>
                        <a href='/' className="mobile-logo">
                            <img src='images/logo.png'/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </>
  )
}

export default MobileHeader