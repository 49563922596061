import {React,useState} from 'react'
import {BrowserRouter as Router,Switch,Route,Link,NavLink} from "react-router-dom"
import {Button,Modal} from 'react-bootstrap';


function Header() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const menuToggle = () => {
        document.body.classList.toggle('menu-toggle');
    }

    
  return (
    <>


<header>
    <nav className="bg-white">
        <div className="container d-flex align-items-center">
            <a href='/' className="navbar-brand">
                <img src='images/logo.png'/>
            </a>
            <div className='ms-auto d-none d-md-block position-relative'>
                <input className="form-control rounded-pill search-field" type="search" placeholder="Search games..." aria-label="Search"/>
                <svg className="icon search-icon position-absolute">
                    <use href="#icon_search"></use>
                </svg>
            </div>
            <div className="d-flex ms-auto align-items-center" role="search">

                <button type='button' className='btn-coin rounded-pill' onClick={handleShow}>
                    <img src='images/coin.svg' alt='coin'/>
                    <span>00.00</span>
                    <img src='images/rounded-plus.svg' alt='plus'/>
                </button>

                <button type='button' className='btn-coin use-btn rounded-pill ms-md-3 ms-2'>
                    <img src='images/user.svg' alt='user'/>
                    <span>User</span>
                </button>

                {/* <button type="button" className="btn btn-login rounded-pill fw-500">Login</button> */}

                <div className='country-box ms-md-3 d-none d-lg-block'>
                    <button type='button' className="country-btn" data-bs-toggle="collapse" data-bs-target="#collapseCountryList" aria-expanded="false" aria-controls="collapseCountryList">
                        <img src='images/india-flag.svg' alt='flag'/>
                        <span className='mx-2'>IN</span>
                        <svg className="icon">
                            <use href="#icon_chevrondown"></use>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </nav>
    <div className="navbar navbar-expand-lg bg-white p-0">
        <div className="container">
            {/* back arrow button */}
            {/* <button className="navbar-toggler mb-2 back-btn" type="button" aria-label="back navigation">
                <svg className="icon">
                    <use href="#icon_backarrow"></use>
                </svg>
            </button> */}


            <button className="navbar-toggler mb-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <svg className="icon">
                    <use href="#icon_menu"></use>
                </svg>
            </button>

            <button type='button' className='ms-auto btn border-0 p-0 m-0 pt-1 d-flex align-items-center justify-content-center me-2 d-block d-md-none'>
                <svg className="icon search-icon fs-5">
                    <use href="#icon_search"></use>
                </svg>
            </button>

            <div className='country-box navbar-toggler border-0'>
                <button type='button' className="country-btn" data-bs-toggle="collapse" data-bs-target="#collapseCountryList" aria-expanded="false" aria-controls="collapseCountryList">
                    <img src='images/india-flag.svg' alt='flag'/>
                    <span className='mx-2'>IN</span>
                </button>
            </div>
            <div className="collapse navbar-collapse " id="navbarNavAltMarkup">
                <div className="navbar-nav d-flex justify-content-evenly w-100 fs-16px fw-500">
                    <a className="nav-link" aria-current="page" href="#">Home</a>
                    <a className="nav-link" href="#">Orders</a>
                    <a className="nav-link" href="#">My Wallet</a>
                    <a className="nav-link" href="#">Profile</a>
                    <a className="nav-link" href="#">About Us</a>
                    <a className="nav-link" href="#">Reseller</a>
                </div>
            </div>
        </div>
    </div>
</header>


<div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div className="offcanvas-header">
    <div className='offcanvas-logo'>
        <a href='/' className="w-100">
            <img src='images/logo.png'/>
        </a>
    </div>
    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div className="offcanvas-body position-relative">
    <ul className="offcanvas-aside">
        <li>
            <NavLink className="nav-link" to="/">
                <span>
                    <svg className="icon">
                        <use href="#icon_profile"></use>
                    </svg>
                </span>
                <strong>Profile</strong>
                <svg className="icon ms-auto">
                    <use href="#icon_rightarrow"></use>
                </svg>
            </NavLink>
        </li>
        <li>
            <NavLink className="nav-link" to="/">
                <span>
                    <svg className="icon">
                        <use href="#icon_order"></use>
                    </svg>
                </span>
                <strong>Orders</strong>
                <svg className="icon ms-auto">
                    <use href="#icon_rightarrow"></use>
                </svg>
            </NavLink>
        </li>
        <li>
            <NavLink className="nav-link" to="/">
                <span>
                    <svg className="icon">
                        <use href="#icon_wallet"></use>
                    </svg>
                </span>
                <strong>My Wallet</strong>
                <svg className="icon ms-auto">
                    <use href="#icon_rightarrow"></use>
                </svg>
            </NavLink>
        </li>
        <li>
            <NavLink className="nav-link" to="/">
                <span>
                    <svg className="icon">
                        <use href="#icon_chat"></use>
                    </svg>
                </span>
                <strong>Help & Support</strong>
                <svg className="icon ms-auto">
                    <use href="#icon_rightarrow"></use>
                </svg>
            </NavLink>
        </li>
        <li>
            <NavLink className="nav-link" to="/">
                <span>
                    <svg className="icon">
                        <use href="#icon_handshake"></use>
                    </svg>
                </span>
                <strong>Reseller</strong>
                <svg className="icon ms-auto">
                    <use href="#icon_rightarrow"></use>
                </svg>
            </NavLink>
        </li>
        <li>
            <NavLink className="nav-link" to="/">
                <span>
                    <svg className="icon">
                        <use href="#icon_info"></use>
                    </svg>
                </span>
                <strong>About Us</strong>
                <svg className="icon ms-auto">
                    <use href="#icon_rightarrow"></use>
                </svg>
            </NavLink>
        </li>
        <li>
            <NavLink className="nav-link" to="/">
                <span>
                    <svg className="icon">
                        <use href="#icon_share"></use>
                    </svg>
                </span>
                <strong>Share</strong>
                <svg className="icon ms-auto">
                    <use href="#icon_rightarrow"></use>
                </svg>
            </NavLink>
        </li>
    </ul>
    <button type='button' className='btn rounded-pill'>
        <svg className="icon me-1">
            <use href="#icon_logout"></use>
        </svg>
        Log out
    </button>
  </div>
</div>



<div className="language-dropdown dropdownList collapse" id='collapseCountryList'>
    <div className="offcanvas-header d-flex">
        <button type="button" className="btn-close ms-auto py-3 my-1 me-3" data-bs-toggle="collapse" data-bs-target="#collapseCountryList" aria-expanded="false" aria-controls="collapseCountryList" aria-label="Close"></button>
    </div>
    <div className='container'>
        <div className='row'>
            <div className='col-12'>
                <h5>Select your country</h5>
            </div>
            <div className='col-md-6 country-list' id="columnslist">
                <ul className='offcanvas-right'>
                    <li>
                        <a href='#' className="nav-link" >
                            <span>
                                <img src='images/india-flag.svg' alt='flag'/>
                            </span>
                            <strong>India</strong>
                        </a>
                    </li>
                    <li>
                        <a href='#' className="nav-link" >
                            <span>
                                <img src='images/india-flag.svg' alt='flag'/>
                            </span>
                            <strong>India</strong>
                        </a>
                    </li>
                    <li>
                        <a href='#' className="nav-link" >
                            <span>
                                <img src='images/india-flag.svg' alt='flag'/>
                            </span>
                            <strong>India</strong>
                        </a>
                    </li>
                    <li>
                        <a href='#' className="nav-link" >
                            <span>
                                <img src='images/india-flag.svg' alt='flag'/>
                            </span>
                            <strong>India</strong>
                        </a>
                    </li>
                    <li>
                        <a href='#' className="nav-link" >
                            <span>
                                <img src='images/india-flag.svg' alt='flag'/>
                            </span>
                            <strong>India</strong>
                        </a>
                    </li>
                    <li>
                        <a href='#' className="nav-link" >
                            <span>
                                <img src='images/india-flag.svg' alt='flag'/>
                            </span>
                            <strong>India</strong>
                        </a>
                    </li>
                    <li>
                        <a href='#' className="nav-link" >
                            <span>
                                <img src='images/india-flag.svg' alt='flag'/>
                            </span>
                            <strong>India</strong>
                        </a>
                    </li>
                    <li>
                        <a href='#' className="nav-link" >
                            <span>
                                <img src='images/india-flag.svg' alt='flag'/>
                            </span>
                            <strong>India</strong>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div className='lanlist-overlay' data-bs-toggle="collapse" data-bs-target="#collapseCountryList" aria-expanded="false" aria-controls="collapseCountryList"></div>


{/* modal */}
<Modal centered show={show} onHide={handleClose} className='addcoin-modal'>
    <Modal.Header closeButton className='border-0 py-0'>
    </Modal.Header>
    <Modal.Body className='px-md-4 px-3 mt-0 pt-0 pb-md-4'>
        <h5 className='text-center'>Add Coins</h5>
        <p>Note: 1 DOS Coin = ₹1</p>
        <div>
            <label htmlFor="Amount" class="form-label input-label">Amount</label>
            <input type="text" class="form-control form-control-lg input-box" id="Amount" placeholder="Enter Amount"/>
        </div>
        <button type='button' className='btn btn-pay w-100 rounded-pill py-1'>Proceed to Payment</button>
    </Modal.Body>
</Modal>
    </>
  )
}

export default Header