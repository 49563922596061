import { BrowserRouter,Routes ,Route} from 'react-router-dom';
import Home from '../pages/Home';
import Product from '../pages/Product';
import Login from '../pages/Login';
import Otp from '../pages/Otp';


function Routing() {
    return (<>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Login />}/>
                <Route path='/otp' element={<Otp />}/>
                <Route path='/home' element={<Home />}/>
                <Route path='/product' element={<Product/>}/>
            </Routes>
        </BrowserRouter>
    </>)
}

export default Routing;
