import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.js";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routing from './components/Routing';
import Icons from './components/Icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Routing></Routing>
    <Icons></Icons>
  </React.StrictMode>
);

reportWebVitals();

