import { useEffect, useState, createRef } from "react";
import Header from '../components/Header'
import Footer from '../components/Footer'
import MobileHeader from "../components/MobileHeader";
import MobileFooter from "../components/MobileFooter";

function Otp() {
  const [otp, setOtp] = useState(Array(6).fill('')); // Assuming 6 digit OTP
  const otpInputRefs = Array(6).fill(0).map(_ => createRef());

  const handleInputChange = (event, index) => {
    const value = event.target.value;

    if (value && !/^[0-9]$/.test(value)) {
      return;
    }

    setOtp([...otp.slice(0, index), value, ...otp.slice(index + 1)]);

    if (value && index < otpInputRefs.length - 1) {
      otpInputRefs[index + 1].current.focus();
    }

    if (!value && index > 0) {
      otpInputRefs[index - 1].current.focus();
    }
  };


  return (
    <>
      <div className="mainheaderFooter">
        <Header />
      </div>
      <MobileHeader />
      <section className="wrapper">
        <div className='container'>
          <div className='row d-flex justify-content-center'>
            <div className='col-12 col-md-6 my-5'>
              <form className='login-form'>
                <h2 className='text-center'>Verify Your Account</h2>
                <h6 className='text-center'>Please enter the 6-digit OTP sent to <span className="fw-bold">+91 1122334455</span> to verify your account</h6>

                <div className="otp-input-fields">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      className="form-control form-control-lg"
                      type="text"
                      value={digit}
                      onChange={event => handleInputChange(event, index)}
                      ref={otpInputRefs[index]}
                    />
                  ))}
                  {/* <div id="_otp" className={otp.join('').length === 6 ? '_ok' : '_notok'}>
                        {otp.join('')}
                    </div> */}
                </div>

                <button type='button' className='btn btn-pay w-100'>Verify</button>

                <p className='text-center'>Didn't receive the OTP? <a href='#'>Resend OTP</a></p>

              </form>
            </div>
          </div>
        </div>
      </section>
      <div className="mainheaderFooter">
        <Footer />
      </div>
      <MobileFooter />
    </>
  )
}

export default Otp