import { useState } from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'
import {Button,Modal} from 'react-bootstrap';

function Product() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
    <Header/>

    <section className='wrapper product'>
      <div className='container'>
        <div className='row pt-32px g-md-5 g-3'>
          <div className='col-lg col-lg-340 p-0'>
            <div className='game-imgbox'>
              <img src='images/legends.png' alt='legends'/>
            </div>

            <div className='game-content'>
              <div className='pt-3 d-flex'>
                <div className='game-smimgbox me-3'>
                  <img src='images/legends-small.png' alt='legends' className='game-smimg'/>
                </div>
                <div>
                  <h6 className='game-heading'>Mobile Legends</h6>

                  <div className='d-flex align-items-center pt-2'>
                    <svg className="icon ms-3 me-2">
                      <use href="#icon_energy"></use>
                    </svg>
                    <span>Instant Delivery</span>
                      <svg className="icon ms-3 me-2">
                        <use href="#icon_secure"></use>
                      </svg>
                    <span>
                      Secure Payments
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg content-wrapper'>
            <div className='d-flex align-items-center pb-md-3 pb-2'>
              <h5 className='details-heading'>1. Enter Account Details</h5>
              <button type='button' className='question-btn' onClick={handleShow}>
                <svg className="icon">
                  <use href="#icon_question"></use>
                </svg>
              </button>
            </div>

            <div className='row row-cols-1 row-cols-md-2 g-md-3 g-2'>
              <div className='col'>
                <label htmlFor="UserId" class="form-label input-label">User ID</label>
                <input type="text" class="form-control form-control-lg input-box" id="UserId" placeholder="Enter User ID"/>
              </div>

              <div className='col'>
                <label htmlFor="ZoneId" class="form-label input-label">Zone ID</label>
                <input type="text" class="form-control form-control-lg input-box" id="ZoneId" placeholder="Enter Zone ID"/>
              </div>
            </div>

            <div className='d-flex align-items-center check-name pb-md-4 mb-md-2 pb-3'>
              <svg className="icon me-2">
                <use href="#icon_check"></use>
              </svg>
              <span>Nickname123</span>
            </div>

            <h5 className='details-heading'>2. Select a Package</h5>

            <div className='row row-cols-2 row-cols-md-3 g-3 pt-3 pb-4'>
              <div className="col">
                <label className="card-label">
                  <input type="checkbox"/>
                  <div className="cl-content min-h132px">
                    <div className="cl-badge"><small>Popular</small></div>
                    <div className="cl-title">
                      <h4>Weekly Diamond Pass</h4> 
                    </div>
                    <div className="cl-icon">
                      <img src="images/WEEKLY.png" alt='diamond'/>
                    </div>
                    <h6 className="cl-price">₹180</h6>
                  </div>
                </label>
              </div>

              <div className="col">
                <label className="card-label">
                  <input type="checkbox"/>
                  <div className="cl-content min-h132px">
                    <div className="cl-badge"><small>5% OFF</small></div>
                    <div className="cl-title">
                      <h4>172 Diamonds + Weekly Pass</h4> 
                    </div>
                    <div className="cl-icon">
                      <img src="images/diamond-box.png" alt='diamond'/>
                    </div>
                    <h6 className="cl-price">₹464</h6>
                  </div>
                </label>
              </div>

              <div className="col">
                <label className="card-label">
                  <input type="checkbox"/>
                  <div className="cl-content min-h132px">
                    <div className="cl-badge"><small>Popular</small></div>
                    <div className="cl-title">
                      <h4>3x Weekly Pass</h4> 
                    </div>
                    <div className="cl-icon">
                      <img src="images/WEEKLY3.png" alt='diamond'/>
                    </div>
                    <h6 className="cl-price">₹530</h6>
                  </div>
                </label>
              </div>

              <div className="col">
                <label className="card-label">
                  <input type="checkbox"/>
                  <div className="cl-content min-h132px">
                    <div className="cl-badge"><small>Popular</small></div>
                    <div className="cl-title">
                      <h4>5x Weekly Pass</h4> 
                    </div>
                    <div className="cl-icon">
                      <img src="images/WEEKLY5.png" alt='diamond'/>
                    </div>
                    <h6 className="cl-price">₹885</h6>
                  </div>
                </label>
              </div>

              <div className="col">
                <label className="card-label">
                  <input type="checkbox"/>
                  <div className="cl-content min-h132px">
                    <div className="cl-title">
                      <h4>5 Diamonds</h4> 
                    </div>
                    <div className="cl-icon">
                      <img src="images/diamond.png" alt='diamond'/>
                    </div>
                    <h6 className="cl-price">₹10</h6>
                  </div>
                </label>
              </div>

              <div className="col">
                <label className="card-label">
                  <input type="checkbox"/>
                  <div className="cl-content min-h132px">
                    <div className="cl-title">
                      <h4>12 Diamonds</h4> 
                      <span>(11 + 1 Bonus)</span>
                    </div>
                    <div className="cl-icon">
                      <img src="images/diamonds.png" alt='diamond'/>
                    </div>
                    <h6 className="cl-price">₹30</h6>
                  </div>
                </label>
              </div>

              <div className="col">
                <label className="card-label">
                  <input type="checkbox"/>
                  <div className="cl-content min-h132px">
                    <div className="cl-title">
                      <h4>5x Weekly Pass</h4> 
                    </div>
                    <div className="cl-icon">
                      <img src="images/WEEKLY5.png" alt='diamond'/>
                    </div>
                    <h6 className="cl-price">₹885</h6>
                  </div>
                </label>
              </div>

              <div className="col">
                <label className="card-label">
                  <input type="checkbox"/>
                  <div className="cl-content min-h132px">
                    <div className="cl-title">
                      <h4>5 Diamonds</h4> 
                    </div>
                    <div className="cl-icon">
                      <img src="images/diamond.png" alt='diamond'/>
                    </div>
                    <h6 className="cl-price">₹10</h6>
                  </div>
                </label>
              </div>

              <div className="col">
                <label className="card-label">
                  <input type="checkbox"/>
                  <div className="cl-content min-h132px">
                    <div className="cl-title">
                      <h4>12 Diamonds</h4> 
                      <span>(11 + 1 Bonus)</span>
                    </div>
                    <div className="cl-icon">
                      <img src="images/diamonds.png" alt='diamond'/>
                    </div>
                    <h6 className="cl-price">₹30</h6>
                  </div>
                </label>
              </div>

              <div className="col">
                <label className="card-label">
                  <input type="checkbox"/>
                  <div className="cl-content min-h132px">
                    <div className="cl-title">
                      <h4>5x Weekly Pass</h4> 
                    </div>
                    <div className="cl-icon">
                      <img src="images/WEEKLY5.png" alt='diamond'/>
                    </div>
                    <h6 className="cl-price">₹885</h6>
                  </div>
                </label>
              </div>

              <div className="col">
                <label className="card-label">
                  <input type="checkbox"/>
                  <div className="cl-content min-h132px">
                    <div className="cl-title">
                      <h4>5 Diamonds</h4> 
                    </div>
                    <div className="cl-icon">
                      <img src="images/diamond.png" alt='diamond'/>
                    </div>
                    <h6 className="cl-price">₹10</h6>
                  </div>
                </label>
              </div>

              <div className="col">
                <label className="card-label">
                  <input type="checkbox"/>
                  <div className="cl-content min-h132px">
                    <div className="cl-title">
                      <h4>12 Diamonds</h4> 
                      <span>(11 + 1 Bonus)</span>
                    </div>
                    <div className="cl-icon">
                      <img src="images/diamonds.png" alt='diamond'/>
                    </div>
                    <h6 className="cl-price">₹30</h6>
                  </div>
                </label>
              </div>
            </div>

            <h5 className='details-heading'>3. Choose Payment Method</h5>

            <div className='row py-md-4 py-2 g-md-4 g-3'>
              <div className='col-12'>
                <label className="card-label">
                  <input type="checkbox"/>
                  <div className="cl-content payment-card">
                    <div className="cr-badge"><small>Get 5-10% Discount</small></div>
                    <div className='w-100 d-flex  align-items-center'>
                      <div className='left-coin'>
                        <div className='img-box'>
                          <img src='images/dos.png' alt='coin'/>
                        </div>
                        <h3 className='coin-name'>DOS Coins</h3>
                      </div>
                      <div className='right-coin ms-auto me-1'>
                        <div className='d-flex align-items-center'>
                          <div className='img-box'>
                            <img src='images/dos.png' alt='coin'/>
                          </div>
                          <div>
                            <h3 className='coin-value'>115</h3>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <p className='rupee ms-auto'>(=₹115)</p>
                        </div>
                        <div className='d-flex'>
                          <del className='old-price ms-auto'>₹120</del>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              </div>

              <div className='col-12'>
                <label className="card-label">
                  <input type="checkbox"/>
                  <div className="cl-content payment-card">
                    <div className='w-100 d-flex  align-items-center'>
                      <div className='left-upi'>
                        <div className='img-box'>
                          <img src='images/upi.png' alt='upi'/>
                        </div>
                        <h3 className='coin-name'>UPI</h3>
                      </div>
                      <div className='right-coin ms-auto me-1'>
                        <div className='d-flex align-items-center'>
                          <div className='img-upibox'>
                            <img src='images/gpay.png' alt='gpay'/>
                          </div>
                          <div className='img-upibox'>
                            <img src='images/phonepe.png' alt='phonepe'/>
                          </div>
                          <div className='img-upibox'>
                            <img src='images/paytm.png' alt='paytm'/>
                          </div>
                          <div className='img-upimore'>
                            <img src='images/plus.svg' alt='more'/>
                          </div>
                          <div>
                            <h3 className='coin-value'>₹120</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              </div>

              <div className='col-12 pay-btnbox'>
                <button type='button' className='btn btn-pay w-100'>Proceed to pay ₹120</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <Footer/>


    <Modal centered show={show} onHide={handleClose} className='detail-modal'>
      <Modal.Header closeButton className='border-0'>
      </Modal.Header>
      <Modal.Body className='p-md-4 p-3 pt-0'>
      <div className='d-flex'>
      </div>
        <p>
          To find your User ID, click on the profile menu in the top left corner of the game's main menu. The ID will be listed as, for example, 123456789(12345). Enter 123456789 in the User ID field and 12345 in the Zone ID field.
        </p>
        <div className='img-details mt-md-4 mt-2'>
          <img src='images/profile_details.png' alt='/profile details'/>
        </div>
      </Modal.Body>
    </Modal>

    </>
  )
}

export default Product