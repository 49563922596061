import { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap'
import Header from "../components/Header";
import Footer from "../components/Footer";
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';



function Home() {

  const navigate = useNavigate()

  const settings = {
    dots: true,
    centerMode: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    slidesToShow: 1,
    swipe: true,
    swipeToSlide: true,
    centerPadding: "160px",
    slidesToScroll: 1,
    padding: "0",
    slideMargin: "0",
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerPadding: "40px",
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
      ,
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      <Header />
      <section className="wrapper">
        <div className='container'>
          <div className='row pb-2'>
            <div className='col-12'>
              <div className="center-slider">
                <Slider {...settings}>
                  <div>
                    <img src="images/slider.png" alt="slider" />
                  </div>
                  <div>
                    <img src="images/slider.png" alt="slider" />
                  </div>
                  <div>
                    <img src="images/slider.png" alt="slider" />
                  </div>
                  <div>
                    <img src="images/slider.png" alt="slider" />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>


        <div className="container py-md-4 pb-3">
          <div className="row">
            <div className="col-12 pb-md-4 pb-3">
              <h2 className="populargame-heading">Popular Games</h2>
            </div>
          </div>
          <div className="row row-cols-3 row-cols-lg-4 g-md-4 g-3 PopularGames">
            <div className="col">
              <div className="card" onClick={() => navigate("/product")}>
                <div className="img-box">
                  <img src="images/legends-game.png" alt="popular-games" />
                </div>
                <div className="card-body pb-0">
                  <h5 className="card-title text-center">Mobile Legends</h5>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="img-box">
                  <img src="images/dos-wallet.png" alt="popular-games" />
                </div>
                <div className="card-body pb-0">
                  <h5 className="card-title text-center">DOS Coins</h5>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="img-box">
                  <img src="images/mobile-legends.png" alt="popular-games" />
                </div>
                <div className="card-body pb-0">
                  <h5 className="card-title text-center">Mobile Legends (Indonesia)</h5>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="img-box">
                  <img src="images/mobile-legends.png" alt="popular-games" />
                </div>
                <div className="card-body pb-0">
                  <h5 className="card-title text-center">Mobile Legends (Russia)</h5>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="img-box">
                  <img src="images/honor.png" alt="popular-games" />
                </div>
                <div className="card-body pb-0">
                  <h5 className="card-title text-center">Honor of Kings</h5>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="img-box">
                  <img src="images/Genshin.png" alt="popular-games" />
                </div>
                <div className="card-body pb-0">
                  <h5 className="card-title text-center">Genshin Impact</h5>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="img-box">
                  <img src="images/pubg.png" alt="popular-games" />
                </div>
                <div className="card-body pb-0">
                  <h5 className="card-title text-center">PUBG Mobile (Global)</h5>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="container py-md-3 pb-3">
          <div className="row">
            <div className="col-12 pb-md-4 pb-3 pt-2">
              <h2 className="populargame-heading">Rewards Center</h2>
            </div>
          </div>
          <div className="row row-cols-3 row-cols-lg-4 g-md-4 g-3 PopularGames">
            <div className="col">
              <div className="card">
                <div className="img-box">
                  <img src="images/free-diamond.png" alt="popular-games" />
                </div>
                <div className="card-body pb-0">
                  <h5 className="card-title text-center">Giveaway Redemption</h5>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-md-4 pt-3">
            <div className="col-12 pb-md-4 pb-2 pt-3">
              <h2 className="populargame-heading">DEMON OFFICIAL STORE</h2>
            </div>
            <div className="col-12">
              <h3 className="OfficialStore-heading">Why top-up on Demon Official Store?</h3>

              <p className="official-paragraph">
                Join the ranks of millions of passionate gamers who turn to Demon Official Store each month to revolutionize their in-game experience. As your ultimate platform for purchasing game credits and vouchers, Demon Official Store takes convenience, speed, and security to unprecedented heights.
              </p>
            </div>

            <div className="row row-cols-1 row-cols-md-2 g-md-4 g-3 pt-0 mt-0">
              <div className="col">
                <div className="content-icon">
                  <div className="icon-box">
                    <img src="images/fast.png" alt="icon" />
                  </div>
                  <div className="paragraph">
                    <h6>Easy and Fast</h6>
                    <p>It only takes few seconds to complete a purchases on Demon Official Store.</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="content-icon">
                  <div className="icon-box">
                    <img src="images/delivery.png" alt="icon" />
                  </div>
                  <div className="paragraph">
                    <h6>Instant Delivery</h6>
                    <p>When you top-up on Demon Official Store, your purchases is delivered to your game account as soon as payment is completed.</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="content-icon">
                  <div className="icon-box">
                    <img src="images/wallet.png" alt="icon" />
                  </div>
                  <div className="paragraph">
                    <h6>Convenient Payment Methods</h6>
                    <p>To ensure your convenience, we are using safe payment methods.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-md-4 pt-3">
            <div className="col-12 pb-md-4 pb-2 pt-3">
              <h2 className="ContactUs-Heading">Contact Us</h2>
            </div>
            <div className="col-12 col-md-6 social-card d-flex justify-content-between">
              <div className="card">
                <div className="card-body d-flex align-items-center justify-content-center">
                  <div>
                    <div className="d-flex justify-content-center">
                      <img className="whatsapp" src="images/whatsapp.png" alt="whatsapp" />
                    </div>
                    <h5 className="card-title text-center">Whatsapp</h5>
                    <a href="#" class="stretched-link"></a>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body d-flex align-items-center justify-content-center">
                  <div>
                    <div className="d-flex justify-content-center">
                      <img className="whatsapp" src="images/messenger.png" alt="messenger" />
                    </div>
                    <h5 className="card-title text-center">Messenger</h5>
                    <a href="#" class="stretched-link"></a>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body d-flex align-items-center justify-content-center">
                  <div>
                    <div className="d-flex justify-content-center">
                      <img className="email" src="images/mail.png" alt="mail" />
                    </div>
                    <h5 className="card-title text-center">Email</h5>
                    <a href="#" class="stretched-link"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className='col-12 mobile-footer'>
              <button type='button' className='btn border-0 ft-btn'>
                <svg className="icon">
                  <use href="#icon_home"></use>
                </svg>
                <p>Home</p>
              </button>
              <button type='button' className='btn border-0 ft-btn'>
                <svg className="icon">
                  <use href="#icon_ft-search"></use>
                </svg>
                <p>Search</p>
              </button>
              <button type='button' className='btn border-0 ft-btn'>
                <svg className="icon">
                  <use href="#icon_order"></use>
                </svg>
                <p>Orders</p>
              </button>
              <button type='button' className='btn border-0 ft-btn'>
                <svg className="icon">
                  <use href="#icon_chat"></use>
                </svg>
                <p>Support</p>
              </button>
            </div>
          </div>
        </div>
      </section>

      <Footer />




    </>
  )
}

export default Home